<template>
  <b-container fluid>
    <button class="mx-1 btn px-4 py-2 btn-success" @click="toPdf()">Generar Desprendible</button>
    <!-- {{persona}} -->
    <form @submit.prevent="saveData">
      <!-- <h4 class="text-center text-black"><b>COLILLA DE PAGO: </b></h4>
      <p class="text-center mt-1 text-black">Conoce el detalle de los devengados y deducciones incluidos en la liquidación de la nómina de este período</p> -->
      <b-row>
        <b-col class="" sm="12">
          <div class="card mt-3 px-5 mb-0">
            <div class="row pt-3">
              <div class="col-md-4">
                <b-form-group label-cols="3" class="mb-0" label="Empoleador" label-for="input-sm">
                  <b-input size="sm" required v-model="documento" placeholder="" type="text" class="form-control" id=" " />
                </b-form-group>
                <b-form-group label-cols="3" class="mt-0" label="Cédula o NIT" label-for="input-sm">
                  <b-input size="sm" required v-model="cedula" placeholder="" type="text" class="form-control" id=" " />
                </b-form-group>
                <!-- <div class="d-flex">
                <label for=" " class="form-label">Tipo de Documento</label>
                <input size="sm" required v-model="persona.NumeroDocumento" placeholder="Escriba el Numero de Documento..." type="text" class="form-control" id=" " />
              </div>
               -->
              </div>
              <div class="col-6">
                <img style="max-height: 300px" src="" alt="" />
              </div>
            </div>
          </div>
          <div class="card mt-1 px-5 py-3 mb-0">
            <p class="text-center">
              <b
                >Comprobante del pago de la prima de servicios correspondiente al segundo semestre 2023Comprobante del pago de la prima de servicios correspondiente al segundo
                semestre 2023</b
              >
            </p>

            <div class="row">
              <div class="col-md-6">
                <div v-for="item in fechaYDia" :key="item.id" class="row">
                  <div class="col-6">
                    <p>{{ item.titulo }}</p>
                  </div>
                  <p>{{ item.valor }}</p>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="row bg-dark py-2" style="border-radius: 10px">
                  <div class="col-8">
                    <span class="pt-3">Base para la liquidación de la prima de servicios (promedio del salario si se trata de uno variable):</span>
                  </div>
                  <div class="col-4 my-3">
                    <p class="align-middle text-right p-0">{{ "$" + baseParaLiquidacion }}</p>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-8">
                    <b class="text-light">Valor de la prima de servicios:</b>
                  </div>
                  <div class="col-4 text-right">
                    <b class="text-light align-middle text-right">{{ "$" + valoPrimaServ }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="px-5 pb-3 pt-0 mt-0">
            <div class="row">
              <div class="col-12">
                <CardTable :data="dataCards" />
              </div>
              <!-- <div class="col-12 col-md">
                  <BtnAddCardTable text="Abrir Ticket" url="AgregarTickets" />
                </div> -->
            </div>
          </div>

          <!-- empleados -->
          <card>
            <div class="row px-5 pt-3">
              <div class="col-6">
                <p><b>Recibí conforme:</b></p>
                <div class="d-flex justify-content-start py-3">
                  <hr style="color: white; border-color: white; width: 200px; margin: 0px" />
                </div>
                <b>{{ item }}</b>
              </div>
              <div class="col-6"></div>
            </div>
          </card>
        </b-col>
      </b-row>
    </form>

    <form @submit.prevent="saveData">
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="2400"
        filename="desprendible"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        :pdf-margin="10"
        pdf-orientation="portrait"
        pdf-content-width="1000px"
        @progress="onProgress($event)"
        ref="html2Pdf"
        :html-to-pdf-options="htmltopdfoptions"
      >
        <!-- </vue-html2pdf> -->

        <section class="m-1 mt-4 text-center bgwhite" slot="pdf-content">
          <h4 class="text-center text-dark"><b>COLILLA DE PAGO: </b></h4>
          <p class="text-center mt-3 text-dark">Detalle de los devengados y deducciones incluidos en la liquidación de la nómina de este período</p>
          <b-row class="bgwhite">
            <b-col sm="12">
              <card class="bgwhite">
                <template v-slot:headerTitle> </template>
                <div class="row px-5 mb-0 text-dark bgwhite">
                  <div class="col-md-4 text-left pr-4">
                    <div class="mt-4 row text-left py-4 pl-0 rounded bg-none">
                      <div class="col-4 mt-0 pr-0 mr-0">
                        <label class="form-label bold">Nombres:</label><br />
                        <label class="form-label bold">Apellidos:</label><br />
                        <label class="form-label bold">Identificación:</label><br />
                        <label class="form-label bold">Cargo:</label><br />
                      </div>
                      <div class="col-6 mt-0 pl-0 ml-0">
                        <label class="form-label">{{ item.persona ? item.persona.nombres : "" }}</label
                        ><br />
                        <label class="form-label">{{ item.persona ? item.persona.apellidos : "" }}</label
                        ><br />
                        <label class="form-label">{{ item.persona.numeroDocumento }}</label
                        ><br />
                        <label class="form-label">--</label><br />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 text-left pr-4">
                    <div class="mt-4 row text-left py-4 pl-0 rounded bg-none">
                      <div class="col-4 mt-0 pr-0 mr-0">
                        <label class="form-label bold">Ciudad:</label><br />
                        <label class="form-label bold">Dirección:</label><br />
                        <label class="form-label bold">Correo:</label><br />
                      </div>
                      <div class="col-6 mt-0 pl-0 ml-0">
                        <label class="form-label">--</label><br />
                        <label class="form-label">{{ persona.Direccion }}</label
                        ><br />
                        <label class="form-label">{{ item.persona ? item.persona.correo : "" }}</label
                        ><br />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 text-left pr-4">
                    <div class="mt-4 row text-left py-4 pl-0 rounded bg-none">
                      <div class="col-4 mt-0 pr-0 mr-0">
                        <label class="form-label bold">Inicio liq.:</label><br />
                        <label class="form-label bold">Fin liq.:</label><br />
                        <label class="form-label bold">Mét. de pago:</label><br />
                        <label class="form-label bold">Días liquidados:</label><br />
                      </div>
                      <div class="col-6 mt-0 pl-0 ml-0">
                        <label class="form-label">--</label><br />
                        <label class="form-label">--</label><br />
                        <label class="form-label">0</label><br />
                        <label class="form-label">0</label><br />
                      </div>
                    </div>
                  </div>
                </div>
              </card>

              <!-- <div class="px-5 pb-3">
              <div class="row">
                <div class="col-12">
                  <CardTable :data="dataCards" />
                </div>
              </div>
            </div> -->

              <!-- empleados -->
              <card class="bg-none mt-0 bgwhite">
                <div class="row px-5 pt-3 bgwhite">
                  <div class="col-6 bgwhite">
                    <p class="text-dark"><b>INGRESOS</b></p>
                    <table class="table bg-white">
                      <thead class="bg-white">
                        <tr class="bg-white">
                          <th class="bg-white" scope="col">Concepto</th>
                          <th class="bg-white" scope="col">valor</th>
                        </tr>
                      </thead>
                      <tbody class="border" v-for="itemTable of nominaLiquidacionPersonaDetalle" :key="itemTable.id">
                        <template v-if="itemTable.tDev > 0">
                          <tr>
                            <td class="border">{{ itemTable.concepto }}</td>
                            <td class="border">{{ Number(itemTable.tDev).toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-6">
                    <p class="text-dark"><b>DEDUCCIONES</b></p>
                    <table class="table bg-white">
                      <thead class="bg-white">
                        <tr class="bg-white">
                          <th scope="col" class="bg-white">Concepto</th>
                          <th scope="col" class="bg-white">valor</th>
                        </tr>
                      </thead>
                      <tbody class="border" v-for="itemTable2 of nominaLiquidacionPersonaDetalle" :key="itemTable2.id">
                        <tr class="border">
                          <template v-if="itemTable2.tDed > 0">
                            <td class="border">{{ itemTable2.concepto }}</td>
                            <td class="border">{{ Number(itemTable2.tDed).toLocaleString("es-CO", { style: "currency", currency: "COP" }) }}</td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="text-dark text-left ml-5 mr-5 p-1 border">
                  <div class="row">
                    <div class="col-3">
                      <span class="text-dark bold m-3">TOTALES</span>
                    </div>
                    <div class="col-3">
                      <span class="text-dark bold"
                        >Devengos:{{
                          nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDev), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" })
                        }}</span
                      >
                    </div>
                    <div class="col-3">
                      <span class="text-dark bold"
                        >Deducciones:{{
                          listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalDeducciones), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" })
                        }}</span
                      >
                    </div>
                    <div class="col-3">
                      <span class="text-dark bold"
                        >Neto a pagar:{{
                          listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalPago), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" })
                        }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col-6">
                    _______________________________________<br />
                    &nbsp; Recibido trabajador
                  </div>
                </div>
              </card>
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </form>
  </b-container>
</template>
<script>
import { core } from "../../../../config/pluginInit";
import CardTable from "../../../../components/cardTable/CardTableV2.vue";
const Swal = require("sweetalert2");
//import Vue from 'vue';
import VueHtml2pdf from "vue-html2pdf";

//Vue.use(VueHtml2pdf);

export default {
  components: {
    CardTable,
    VueHtml2pdf,
  },
  name: "DataTable",
  data() {
    return {
      documento: "",
      cedula: "",
      baseParaLiquidacion: "13000.606",
      valoPrimaServ: "13000.606",
      fechaYDia: [
        { id: 1, titulo: "Fecha inicial", valor: "01/05/2024" },
        { id: 2, titulo: "Fecha final", valor: "Fecha inicial" },
        { id: 3, titulo: "Días trabajados en el semestre", valor: "Fecha inicial" },
      ],

      usuario: { rol: { nivel: 0 } },
      id: "",
      selected: null,
      item: {
        fechaInicio: null, //this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
        fechaFin: null, //this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
        CantidadEmpleados: 0.0,
        TotalSalarios: 0,
        TotalPagosAdicionales: 0,
        TotalDeducciones: 0,
        TotalCostos: 0,
        TotalAPagar: 0,
        EmpresaId: "",
        SucursalId: "",
        Descripcion: "",
        Estado: 1,
        persona: { nombres: "", apellidos: "", numeroDocumento: "", correo: "" },
      },
      ListEmpresas: [],
      ListSucursales: [],
      listaItems: [],
      listaEmpleados: [],
      nominaConfiguracion: {},
      listaDeducciones: [],
      listaDevengados: [],
      listaLiquidacionPersona: [],
      listaLiquidacionPersonaDetalle: [],
      nominaLiquidacionPersonaDetalle: [],
      nuevoItem: {},
      usuarioSeleccionado: null,
      porcenta: 0.75,
      porcenta2: 1.1,
      porcenta3: 1,
      valporcenta: 1,
      valporcenta2: 1,
      valporcenta3: 1,
      dataCards: [
        // {
        //   title: "Salario",
        //   value: () => this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.salario), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
        //   iconName: "money-bill-1",
        //   color: "rgba(71, 136, 255, 1)",
        //   tipo: "shield",
        //   animated: "bounce",
        // },
        // {
        //   title: "Ingresos adicionales",
        //   value: () =>
        //     this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalIngresosAdicionales), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
        //   iconName: "hand-point-up",
        //   color: "rgba(71,136,255,1)",
        //   tipo: "shield",
        //   animated: "bounce",
        // },
        // {
        //   title: "Deducciones",
        //   value: () => this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalDeducciones), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
        //   iconName: "hand-point-down",
        //   color: "rgba(223, 18, 46, 1)",
        //   tipo: "shield",
        //   animated: "bounce",
        // },
        // {
        //   title: "Total a pagar",
        //   value: () => this.listaLiquidacionPersona.reduce((prev, curr) => prev + Number(curr.totalPago), 0).toLocaleString("es-CO", { style: "currency", currency: "COP" }),
        //   iconName: "hand-holding-dollar",
        //   color: "rgba(71, 136, 255, 1)",
        //   tipo: "shield",
        //   animated: "bounce",
        // },
        // {
        //   title: "Total Costo",
        //   value: () =>
        //   this.listaItems.filter(
        //       (tick) => tick.prioridad == 1
        //     ).length,
        //   iconName: "hand-point-up",
        //   color: "rgba(71, 136, 255, 1)",
        //   tipo: "shield",
        //   animated: "bounce",
        //   onActivate: () => {
        //     this.dataTableInstance.search("Baja").draw();
        //     console.log("Activated");
        //   },
        //   onDeactivate: () => {
        //     this.dataTableInstance.search("").draw();
        //     console.log("Deactivate");
        //   },
        // },
      ],
      usuario_id: null,
      htmltopdfoptions: {
        margin: 0.5,
        image: {
          type: "jpeg",
          quality: 0.98,
        },

        enableLinks: false,

        html2canvas: {
          scale: 1,
        },

        jsPDF: {
          unit: "in",
          format: "a4",
          // orientation: "portrait",
          orientation: "landscape",
        },
      },
      persona: {},
    };
  },

  async mounted() {
    this.$isLoading(true);
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.item.EmpresaId = this.usuario.empresa.id;
    this.usuario_id = this.$route.params.user;
    await this.getPersona();

    this.id = this.$route.params.id;
    await this.getDeducciones();
    await this.getDevengados();
    await this.getEmpleados();
    await this.getNominaConfiguracion();
    await this.getData();
    // await this.getEmpresas();
    // await this.getSucursalEmpresa();
    core.index();
    window.$("#datatable_empleados").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });

    window.$("#datatable_empleados2").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },

  methods: {
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    toPdf() {
      //html2pdf(document.getElementById('exportpdf'));
      //this.$refs.html2Pdf.generatePdf();
      this.$refs.html2Pdf.generatePdf();
    },
    async showModalEmpleados() {
      for await (let itemp of this.listaEmpleados) {
        let idt = this.listaLiquidacionPersona.findIndex((x) => x.persona.id == itemp.id);
        console.log(idt);
        if (idt > -1) {
          itemp.show = true;
        } else {
          itemp.show = false;
        }
      }
      this.$bvModal.show("my-modal");
      this.$forceUpdate();
    },
    async recargandoDatos() {
      this.$isLoading(true);
      await this.getData();
      await this.getEmpleados();
      await this.getNominaConfiguracion();
      //await this.getDeducciones();
      //await this.getDevengados();
      // await this.getEmpresas();
      // await this.getSucursalEmpresa();
      // core.index();
      // window.$("#datatable_empleados").DataTable({
      //   language: {
      //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      //   },
      // });

      // window.$("#datatable_empleados2").DataTable({
      //   language: {
      //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      //   },
      // });
      this.$isLoading(false);
    },

    deleteRow(id) {
      Swal.fire({
        title: "¿Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          //let loader = this.$loading.show();
          let res = await this.$store.dispatch("hl_delete", {
            path: "NominaLiquidacion/DelNominaLiquidacionPersona/" + id,
          });
          if (res) {
            await this.recargandoDatos();
            Swal.fire("Listo!", "Registro eliminado correctamente.", "success");
          }
        }
      });
    },
    async modalDetalle(it) {
      try {
        for await (let itemDetalle of it.nominaLiquidacionPersonaDetalle) {
          if (itemDetalle.tDev > 0) {
            console.log("lista dev", itemDetalle.conceptoId);
            console.log("lista ", this.listaDevengados);

            let filtroDev = this.listaDevengados.filter((x) => x.listaDevengadoId == itemDetalle.conceptoId);

            console.log("lista dev", filtroDev);

            itemDetalle.concepto = filtroDev[0].listaDevengado.descripcion;
          }
          if (itemDetalle.tDed > 0) {
            let filtroDed = this.listaDeducciones.filter((x) => x.listaDeduccionId == itemDetalle.conceptoId);
            itemDetalle.concepto = filtroDed[0].listaDeduccion.descripcion;
          }
        }
        this.nominaLiquidacionPersonaDetalle = it.nominaLiquidacionPersonaDetalle;
        console.log(this.nominaLiquidacionPersonaDetalle);
        this.$bvModal.show("miModalDetalle");
      } catch (error) {
        console.log("error", error);
      }
    },
    async actualizaCalculosEmpleadoSel() {
      let id = this.listaLiquidacionPersona.findIndex((x) => x.persona.id == this.usuarioSeleccionado.persona.id);

      this.listaLiquidacionPersona[id].totalIngresosAdicionales = this.nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDev), 0);
      this.listaLiquidacionPersona[id].totalDeducciones = this.nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDed), 0);
      this.listaLiquidacionPersona[id].totalPago =
        this.nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDev), 0) -
        this.nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + Number(curr.tDed), 0);
      //       let dataPersona = {
      //   dataId: this.listaLiquidacionPersona + 1,
      //   id: null,
      //   liquidacionId: null,
      //   estado: 0,
      //   totalIngresosAdicionales: 0,
      //   totalDeducciones: 0,
      //   totalPago: 0,
      //   metodoPago: 0,
      //   diasLiquidados: 0,
      //   persona: item,
      //   nominaLiquidacionPersonaDetalle: [],
      // };
    },
    async guardarDataUsuario() {
      console.log("this.listaLiquidacionPersona ", this.listaLiquidacionPersona);
      let id = this.listaLiquidacionPersona.findIndex((x) => x.persona.id == this.usuarioSeleccionado.persona.id);
      console.log("id--->", id);
      this.listaLiquidacionPersona[id].nominaLiquidacionPersonaDetalle = this.nominaLiquidacionPersonaDetalle;
      console.log("this.listaLiquidacionPersona---> ", this.listaLiquidacionPersona[id]);

      if (this.listaLiquidacionPersona[id].nominaLiquidacionPersonaDetalle.length > 0) {
        let dataDetalle = this.listaLiquidacionPersona[id].nominaLiquidacionPersonaDetalle;
        for await (let itnlpd of dataDetalle) {
          console.log(itnlpd);

          let dataLiquidaPersonaDetalle = {
            Id: itnlpd.id,
            nominaLiquidacionPersonaId: this.listaLiquidacionPersona[id].id,
            conceptoId: itnlpd.conceptoId,
            BaseLiquida: itnlpd.baseLiquida,
            porcentajeTrabajador: itnlpd.porcentajeTrabajador,
            valorTrabajador: itnlpd.valorTrabajador,
            porcentajeEmpleador: itnlpd.porcentajeEmpleador,
            valorEmpleador: itnlpd.valorEmpleador,
            Dias: itnlpd.dias,
            Valor: itnlpd.valor,
            TDev: itnlpd.tDev,
            TDed: itnlpd.tDed,
          };
          console.log(dataLiquidaPersonaDetalle);

          let res = await this.$store.dispatch("hl_post", {
            path: "NominaLiquidacion/NominaLiquidacionPersonaDetalle",
            data: dataLiquidaPersonaDetalle,
          });
          console.log("res", res);
        }
      }
    },
    eliminarItem(item) {
      console.log(item);
      this.nominaLiquidacionPersonaDetalle.splice(
        this.nominaLiquidacionPersonaDetalle.findIndex((x) => x.concepto == item.concepto),
        1
      );
      this.actualizaCalculosEmpleadoSel();
      this.$forceUpdate();
    },
    async agregarNuevoItem() {
      try {
        //this.listaLiquidacionPersona
        if (this.nuevoItem.tipo == "devengado") {
          // //DEVENGADOS

          let itDevengados = this.listaDevengados.find((x) => x.listaDevengado.id == this.nuevoItem.itemId);
          console.log("this.nuevoItem ", this.nuevoItem);
          console.log("item itdevengado ", itDevengados);
          switch (itDevengados.listaDevengado.descripcion) {
            case "Cesantías":
              if (this.usuarioSeleccionado.persona.salario == this.nominaConfiguracion.salarioMinimo) {
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDevengados.listaDevengado.descripcion,
                  conceptoId: itDevengados.listaDevengado.id,
                  baseLiquida: this.usuarioSeleccionado.persona.salario + this.nominaConfiguracion.auxilioDeTransporte,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: this.nominaConfiguracion.cesantias,
                  valorEmpleador: (((this.usuarioSeleccionado.persona.salario + this.nominaConfiguracion.auxilioDeTransporte) * this.nominaConfiguracion.cesantias) / 100).toFixed(
                    2
                  ),
                  dias: this.nuevoItem.dias,
                  valor: this.nuevoItem.valor,
                  tDev: this.nuevoItem.total,
                  tDed: 0,
                });
                this.nuevoItem = {};
              }
              break;
            case "Prima salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.usuarioSeleccionado.persona.salario + this.nominaConfiguracion.auxilioDeTransporte,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: this.nominaConfiguracion.primaDeServicios,
                valorEmpleador: (
                  ((this.usuarioSeleccionado.persona.salario + this.nominaConfiguracion.auxilioDeTransporte) * this.nominaConfiguracion.primaDeServicios) /
                  100
                ).toFixed(2),
                dias: this.nuevoItem.dias,
                valor: this.nuevoItem.valor,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Viáticos salariales":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.valor,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.valor,
                tDev: this.nuevoItem.valor,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Viáticos no salariales":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.valor,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.valor,
                tDev: this.nuevoItem.valor,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Horas extras diurnas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Horas extras nocturnas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Horas extras dominicales y festivas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Horas extras nocturnas dominicales y festivas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Horas recargo nocturno":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Horas recargo dominical y festiva":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Hora recargo nocturno dominical y festivos":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.total,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Vacaciones disfrutadas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: this.nuevoItem.valor,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Vacaciones compensadas no disfrutadas":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: this.nuevoItem.valor,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Prima no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: 0,
                valor: this.nuevoItem.valor,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Incapacidad común":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Incapacidad profesional":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Incapacidad laboral":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Licencia de maternidad o paternidad":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Licencia remunerada":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Licencia no remunerada":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Bonificación salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Bonificación no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Auxilio salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Auxilio no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Huelga legal":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Otro concepto salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Otro concepto no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Compensación ordinaria":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Compensación extraordinaria":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Bono de alimentación salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Bono de alimentación no salarial":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Otros bonos salariales":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Otros bonos no salariales":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Comisión":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Pago a terceros":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Anticipo":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Dotación":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Apoyo sostenimiento":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Teletrabajo":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Bonificación por retiro":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Indemnización":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
            case "Reintegro":
              this.nominaLiquidacionPersonaDetalle.push({
                concepto: itDevengados.listaDevengado.descripcion,
                conceptoId: itDevengados.listaDevengado.id,
                baseLiquida: this.nuevoItem.total,
                porcentajeTrabajador: 0,
                valorTrabajador: 0,
                porcentajeEmpleador: 0,
                valorEmpleador: 0,
                dias: this.nuevoItem.dias,
                valor: 0,
                tDev: this.nuevoItem.total,
                tDed: 0,
              });
              this.nuevoItem = {};

              break;
          }
        } else {
          console.log("nuevoitem in agregarnuevoitem", this.nuevoItem);
          if (this.nuevoItem.tipo == "deduccion") {
            console.log("es deduccion.....");
            let itDeduccion = this.listaDeducciones.find((x) => x.listaDeduccion.id == this.nuevoItem.itemId);
            console.log("this.nuevoItem ", this.nuevoItem);
            console.log("item itdevengado ", itDeduccion);
            switch (itDeduccion.listaDeduccion.descripcion) {
              case "Sindicato":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Sanción pública":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Sanción privada":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Libranza":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Pago a terceros":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Anticipos":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Otras deducciones":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Pensión voluntaria":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Retención en la fuente":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "AFC":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Cooperativa":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Embargo fiscal":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Plan complementario":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Educación":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Reintegro":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
              case "Deuda":
                this.nominaLiquidacionPersonaDetalle.push({
                  concepto: itDeduccion.listaDeduccion.descripcion,
                  conceptoId: itDeduccion.listaDeduccion.id,
                  baseLiquida: this.nuevoItem.total,
                  porcentajeTrabajador: 0,
                  valorTrabajador: 0,
                  porcentajeEmpleador: 0,
                  valorEmpleador: 0,
                  dias: 0,
                  valor: this.nuevoItem.valor,
                  tDed: this.nuevoItem.total,
                  tDev: 0,
                });
                this.nuevoItem = {};

                break;
            }
          }
        }
        this.actualizaCalculosEmpleadoSel();
        this.$forceUpdate();
      } catch (error) {
        console.log("error", error);
      }
    },
    obtenerDescripcionItem(tipo, itemId) {
      if (tipo == "devengado") {
        let res = this.listaDevengados.find((x) => x.listaDevengadoId == itemId);
        console.log("----", tipo, itemId, res);
        console.log("--- this.listaDevengados -", this.listaDevengados);
        return res.listaDevengado.descripcion;
      } else {
        let res = this.listaDeducciones.find((x) => x.listaDeduccionId == itemId);
        console.log("----", tipo, itemId, res);
        console.log("--- this.listaDeducciones -", this.listaDeducciones);
        return res.listaDeduccion.descripcion;
      }
    },
    calculoNuevoItem() {
      setTimeout(() => {
        if (["Cesantías", "Prima salarial"].includes(this.nuevoItem.descripcion)) {
          this.nuevoItem.total = (this.nuevoItem.dias * this.nuevoItem.valor) / 360;
          this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
          this.$forceUpdate();
        } else {
          if (["Viáticos salariales", "Viáticos no salariales"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor;
            this.$forceUpdate();
          }
          if (["Horas extras diurnas"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * 0.25;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2);
            this.$forceUpdate();
          }
          if (["Horas extras nocturnas"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * 0.75;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2);
            this.$forceUpdate();
          }
          if (["Horas extras dominicales y festivas"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * 1;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2);
            this.$forceUpdate();
          }
          if (["Horas extras nocturnas dominicales y festivas"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * 1.5;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2);
            this.$forceUpdate();
          }
          if (["Horas recargo nocturno"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * 0.35;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2).toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Horas recargo dominical y festiva"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * this.porcenta;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2).toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Hora recargo nocturno dominical y festivos"].includes(this.nuevoItem.descripcion)) {
            let total = 0;
            let horasMes = 240;
            let precioHora = this.usuarioSeleccionado.persona.salario / horasMes;
            let porcentajeAdicional = precioHora * this.porcenta2;
            total = (porcentajeAdicional + precioHora) * this.nuevoItem.valor;

            this.nuevoItem.total = total.toFixed(2).toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Vacaciones disfrutadas"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.nuevoItem.dias * this.nuevoItem.valor) / 30;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Vacaciones compensadas no disfrutadas"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.nuevoItem.dias * this.nuevoItem.valor) / 30;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Prima no salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Incapacidad común"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.usuarioSeleccionado.persona.salario / 30) * this.nuevoItem.dias * this.porcenta3;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Incapacidad profesional"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.usuarioSeleccionado.persona.salario / 30) * this.nuevoItem.dias;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Incapacidad laboral"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.usuarioSeleccionado.persona.salario / 30) * this.nuevoItem.dias;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Licencia de maternidad o paternidad"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.usuarioSeleccionado.persona.salario / 30) * this.nuevoItem.dias;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Licencia remunerada"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.usuarioSeleccionado.persona.salario / 30) * this.nuevoItem.dias;
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Licencia no remunerada"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = 0;
            this.$forceUpdate();
          }
          if (["Bonificación salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Bonificación no salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Auxilio salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Auxilio no salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Huelga legal"].includes(this.nuevoItem.descripcion)) {
            //falta verificar como se calcula
            this.nuevoItem.total = 0;
            this.$forceUpdate();
          }
          if (["Otro concepto salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Otro concepto no salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Compensación ordinaria"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Compensación extraordinaria"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Bono de alimentación salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Bono de alimentación no salarial"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Otros bonos salariales"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Otros bonos no salariales"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Comisión"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Pago a terceros"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Anticipo"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Dotación"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Apoyo sostenimiento"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Teletrabajo"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Bonificación por retiro"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Indemnización"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Reintegro"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          //deducciones
          if (["Sindicato"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = (this.usuarioSeleccionado.persona.salario / 30) * (this.nuevoItem.valor * 0.01);
            this.nuevoItem.total = this.nuevoItem.total.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Sanción pública"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Sanción privada"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Libranza"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Pago a terceros"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Anticipos"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Otras deducciones"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Pensión voluntaria"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Retención en la fuente"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["AFC"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Cooperativa"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Embargo fiscal"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Plan complementario"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Educación"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Reintegro"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
          if (["Deuda"].includes(this.nuevoItem.descripcion)) {
            this.nuevoItem.total = this.nuevoItem.valor.toLocaleString("es");
            this.$forceUpdate();
          }
        }
        console.log("cal", this.nuevoItem.total);
      }, 1000);
    },
    async selecionarItem(e, tipo) {
      try {
        this.nuevoItem.tipo = tipo;
        this.nuevoItem.itemId = e;
        this.nuevoItem.dias = 0;
        this.nuevoItem.valor = 0;
        this.nuevoItem.total = 0;
        this.porcenta = 0.75;
        this.porcenta2 = 1.1;
        this.porcenta3 = 1;
        this.valporcenta = 1;
        this.valporcenta2 = 1;
        this.valporcenta3 = 1;
        this.nuevoItem.descripcion = this.obtenerDescripcionItem(tipo, this.nuevoItem.itemId);
        console.log("---------- nuevo item ", this.nuevoItem);
        this.$forceUpdate();
      } catch (error) {
        console.log("error", error);
      }
    },
    async getDevengados() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Devengado/GetDevengados/",
        });
        this.listaDevengados = res;
        console.log("list devengados", this.listaDevengados);
      } catch (error) {
        this.listaDevengados = {};
        console.log("err", error);
      }
    },
    async getDeducciones() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Deduccion/GetDeducciones/",
        });
        this.listaDeducciones = res;
        console.log("list DEDUCCIONES **************", this.listaDeducciones);
      } catch (error) {
        this.listaDeducciones = {};
        console.log("err", error);
      }
    },
    async getNominaConfiguracion() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "NominaConfiguracion/GetNominaConfiguracion/",
        });
        this.nominaConfiguracion = res;

        console.log("list this.nominaConfiguracion ***************************", this.nominaConfiguracion);
      } catch (error) {
        this.nominaConfiguracion = {};
        console.log("err", error);
      }
    },

    async agregarEmpleado(item) {
      console.log(item);
      // validaciones...
      if (item.salario == null || item.salario == 0) {
        Swal.fire("Error!", "Empleado no tiene salario asignado.", "error");

        return;
      }
      this.nominaLiquidacionPersonaDetalle = [];
      this.usuarioSeleccionado = {};
      console.log(item);
      item.estado == 0 ? (item.estado = 1) : (item.estado = 0);
      //inicio creando la data
      let dataPersona = {
        dataId: this.listaLiquidacionPersona + 1,
        id: null,
        liquidacionId: null,
        estado: 0,
        totalIngresosAdicionales: 0,
        totalDeducciones: 0,
        totalPago: 0,
        metodoPago: 0,
        diasLiquidados: 0,
        persona: item,
        nominaLiquidacionPersonaDetalle: [],
      };

      //DEVENGADOS
      for await (let itDevengados of this.listaDevengados) {
        if (itDevengados.listaDevengado.descripcion == "Salario") {
          this.nominaLiquidacionPersonaDetalle.push({
            concepto: itDevengados.listaDevengado.descripcion,
            conceptoId: itDevengados.listaDevengado.id,
            baseLiquida: dataPersona.persona.salario,
            porcentajeTrabajador: 0,
            valorTrabajador: 0,
            porcentajeEmpleador: 0,
            valorEmpleador: dataPersona.persona.salario,
            dias: 0,
            valor: 0,
            tDev: dataPersona.persona.salario,
            tDed: 0,
          });
        }
        if (itDevengados.listaDevengado.descripcion == "Auxilio de transporte") {
          if (dataPersona.persona.salario == this.nominaConfiguracion.salarioMinimo) {
            this.nominaLiquidacionPersonaDetalle.push({
              concepto: itDevengados.listaDevengado.descripcion,
              conceptoId: itDevengados.listaDevengado.id,
              baseLiquida: this.nominaConfiguracion.auxilioDeTransporte,
              porcentajeTrabajador: 0,
              valorTrabajador: 0,
              porcentajeEmpleador: 0,
              valorEmpleador: this.nominaConfiguracion.auxilioDeTransporte,
              dias: 0,
              valor: 0,
              tDev: this.nominaConfiguracion.auxilioDeTransporte,
              tDed: 0,
            });
          }
        }
      }
      //DEDUCCIONES
      for await (let itDeducciones of this.listaDeducciones) {
        //2 devengados obligatorios: salario y auxilio transporte, en el caso de auxilio trans. depende de si es salario minimo
        if (itDeducciones.listaDeduccion.descripcion == "Salud") {
          this.nominaLiquidacionPersonaDetalle.push({
            concepto: itDeducciones.listaDeduccion.descripcion,
            conceptoId: itDeducciones.listaDeduccion.id,
            baseLiquida: dataPersona.persona.salario,
            porcentajeTrabajador: this.nominaConfiguracion.saludTrabajador,
            valorTrabajador: (dataPersona.persona.salario * this.nominaConfiguracion.saludTrabajador) / 100,
            porcentajeEmpleador: this.nominaConfiguracion.saludEmpleador,
            valorEmpleador: (dataPersona.persona.salario * this.nominaConfiguracion.saludEmpleador) / 100,
            dias: 0,
            valor: 0,
            tDev: 0,
            tDed: (dataPersona.persona.salario * this.nominaConfiguracion.saludEmpleador) / 100,
          });
        }
        if (itDeducciones.listaDeduccion.descripcion == "Fondo de pensión") {
          this.nominaLiquidacionPersonaDetalle.push({
            concepto: itDeducciones.listaDeduccion.descripcion,
            conceptoId: itDeducciones.listaDeduccion.id,
            baseLiquida: dataPersona.persona.salario,
            porcentajeTrabajador: this.nominaConfiguracion.pensionTrabajador,
            valorTrabajador: (dataPersona.persona.salario * this.nominaConfiguracion.pensionTrabajador) / 100,
            porcentajeEmpleador: this.nominaConfiguracion.pensionEmpleador,
            valorEmpleador: (dataPersona.persona.salario * this.nominaConfiguracion.pensionEmpleador) / 100,
            dias: 0,
            valor: 0,
            tDev: 0,
            tDed: (dataPersona.persona.salario * this.nominaConfiguracion.pensionEmpleador) / 100,
          });
        }
      }
      ////let total
      dataPersona.totalDeducciones = this.nominaLiquidacionPersonaDetalle.reduce((prev, curr) => prev + curr.valorTrabajador, 0);
      dataPersona.totalPago = this.nominaLiquidacionPersonaDetalle[0].baseLiquida + this.nominaLiquidacionPersonaDetalle[1].baseLiquida - dataPersona.totalDeducciones;
      dataPersona.nominaLiquidacionPersonaDetalle = this.nominaLiquidacionPersonaDetalle;
      this.listaLiquidacionPersona.push(dataPersona);
      this.usuarioSeleccionado = dataPersona;
      await this.actualizaCalculosEmpleadoSel();
      //guardo empleados en db
      //await this.saveData();
    },
    async getSucursalEmpresa() {
      try {
        console.log("get empresas...");
        let res = await this.$store.dispatch("hl_get", {
          path: "Sucursal/ListSucursalEmpresa/" + this.usuario.empresa.id,
        });
        console.log("post sucursal... ", res);
        if (res.length > 0) {
          this.ListSucursales = res;
        } else {
          this.ListSucursales = [];
        }
      } catch (error) {
        this.ListSucursales = [];
        console.log("err", error);
      }
    },
    async getEmpleados() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/ListPersona/" + this.usuario.empresa.id,
        });
        this.listaEmpleados = res;

        console.log("list", this.listaEmpleados);
      } catch (error) {
        this.listaEmpleados = {};
        console.log("err", error);
      }
    },
    async getEmpresas() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "Empresa/ListEmpresa/",
        });
        this.ListEmpresas = res;

        console.log("list", this.ListEmpresas);
      } catch (error) {
        this.ListEmpresas = {};
        console.log("err", error);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "NominaLiquidacion/GetNominaLiquidacionId/" + this.id,
        });
        this.item = res;
        this.item.fechaInicio = this.$moment.tz(this.item.fechaInicio, "America/Costa_Rica").format("YYYY-MM-DD");
        this.item.fechaFin = this.$moment.tz(this.item.fechaFin, "America/Costa_Rica").format("YYYY-MM-DD");
        console.log("////////*////////////*", this.item);
        //obtenemos lista de GetNominaLiquidacionPersonas
        //let str =
        let resLiquidaPersona = await this.$store.dispatch("hl_get", {
          path: "NominaLiquidacion/GetNominaLiquidacionPersonas/" + this.id,
        });
        let item = resLiquidaPersona.filter((x) => x.persona.id == this.usuario_id);
        this.item = item[0];
        this.listaLiquidacionPersona = item; // this.item.nominaLiquidacionPersonaDetalle
        this.nominaLiquidacionPersonaDetalle = this.item.nominaLiquidacionPersonaDetalle;
        console.log("devenga lisra ", this.nominaLiquidacionPersonaDetalle);
        for await (let itemDetalleL of this.nominaLiquidacionPersonaDetalle) {
          console.log("lista itemdetalle", itemDetalleL);
          if (itemDetalleL.tDev > 0) {
            // console.log('lista dev',itemDetalle.conceptoId)
            console.log("lista ", this.listaDevengados);
            let filtroDev = this.listaDevengados.filter((x) => x.id == itemDetalleL.conceptoId);
            console.log("filtroDev", filtroDev);
            itemDetalleL.concepto = filtroDev[0].descripcion;
          }
          if (itemDetalleL.tDed > 0) {
            let filtroDed = this.listaDeducciones.filter((y) => y.id == itemDetalleL.conceptoId);
            console.log("filtroDed", filtroDed);
            itemDetalleL.concepto = filtroDed[0].descripcion;
          }
        }

        console.log("listaLiquidacionPersona----******************> ", this.item);
        console.log("listaLiquidacionPersona nominaLiquidacionPersonaDetalle --- ", this.nominaLiquidacionPersonaDetalle);
        await this.getPersona();
        this.$forceUpdate();
      } catch (error) {
        this.item = {};
        console.log("err", error);
      }
    },
    async getPersona() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/GetPersona/" + this.usuario_id,
        });
        console.log("***********************//********************", res);

        //this.dataPersona = res;
        this.persona.TipoDocumentoId = res.tipoDocumento.id;
        this.persona.numeroDocumento = res.numeroDocumento;
        this.persona.Apellidos = res.apellidos;
        this.persona.Nombres = res.nombres;
        this.persona.FechaNacimiento = res.fechaNacimiento;
        this.persona.FechaContratacion = res.fechaContratacion;
        this.persona.RolId = res.rol.id;
        this.persona.EstadoCivilId = res.estadoCivil.id;
        this.persona.NivelReporte = res.nivelReporte == 0 ? null : res.nivelReporte;
        if (res.nivelReporte != null) {
          // TODO: como actuar en caso de que el usuario recien creado no tenga nivel de reporte?
          if (res.nivelReporte.includes("[")) {
            this.selectedNivelesReporte = JSON.parse(res.nivelReporte);
          } else {
            this.selectedNivelesReporte.push(Number(res.nivelReporte));
          }
        }
        this.persona.PaisId = res.pais.id;
        this.persona.Direccion = res.direccion;
        this.persona.DepartamentoPaisId = res.departamentoPais.id;
        // this.getDepartmentoPaisList(this.persona.PaisId);
        // this.actualizaMunicipios(this.persona.DepartamentoPaisId);
        this.persona.TelefonoFijo = res.telefonoFijo;
        this.persona.MunicipioId = res.municipio.id;
        this.persona.TelefonoMovil = res.telefonoMovil;

        this.persona.Correo = res.correo;
        this.persona.EstadoUsuario = res.estadoUsuario == 0 ? null : res.estadoUsuario;
        this.persona.JefeDirectoId = res.jefeDirectoId;
        this.persona.CorreoNotificaciones = res.correoNotificaciones;
        this.persona.ImagenPerfilUrl = res.imagenPerfilUrl;

        this.persona.TipoContrato = res.tipoContrato;
        this.persona.SalarioIntegral = res.salarioIntegral == true ? "Si" : "No";
        this.persona.Salario = res.salario;
        this.persona.TipoTrabajador = res.tipoTrabajador;
        this.persona.SubtipoTrabajador = res.subtipoTrabajador;
        this.persona.FrecuenciaPago = res.frecuenciaPago;
        this.persona.AltoRiesgo = res.altoRiesgo == true ? 1 : 0;
        this.persona.AuxilioTransporte = res.auxilioTransporte;
        this.persona.SabadoLaboral = res.sabadoLaboral;
        this.persona.NivelRiesgo = res.nivelRiesgo;
        this.persona.DiasVacacionesAcumulados = res.diasVacacionesAcumulados;
        this.persona.MetodoPago = res.metodoPago;
        this.persona.Banco = res.banco;
        this.persona.NumeroCuenta = res.numeroCuenta;
        this.persona.TipoCuenta = res.tipoCuenta;
        this.persona.Eps = res.eps;
        this.persona.CajaCompensacion = res.cajaCompensacion;
        this.persona.FondoPensiones = res.fondoPensiones;
        this.persona.FondoCesantias = res.fondoCesantias;

        //console.log("dataPersona**********************************", res);
      } catch (error) {
        //this.dataPersona = {};
        console.log("errox", error);
      }
    },
    async saveData() {
      //console.log("saving", this.listaLiquidacionPersona);
      for await (let itt of this.listaLiquidacionPersona) {
        let dataLiquidaPersona = {
          Id: itt.id,
          liquidacionId: this.id,
          PersonaId: itt.persona.id,
          Estado: 0,
          Salario: Number(itt.persona.salario),
          totalIngresosAdicionales: Number(itt.totalIngresosAdicionales),
          TotalDeducciones: Number(itt.totalDeducciones),
          TotalPago: Number(itt.totalPago),
          MetodoPago: 0,
          DiasLiquidados: 0,
        };
        console.log(dataLiquidaPersona);
        let res = await this.$store.dispatch("hl_post", {
          path: "NominaLiquidacion/NominaLiquidacionPersona",
          data: dataLiquidaPersona,
        });
        console.log("res", res);
        await this.getData();
      }
    },
  },
};
</script>
<style scoped>
th {
  position: sticky;
  top: 0px; /* 0px if you don't have a navbar, but something is required */
  background: #0c1a30;
}
th {
  position: sticky;
  top: 0px; /* 0px if you don't have a navbar, but something is required */
  background: #0c1a30;
}

.bg-none,
.bg-none > label,
.bg-none > div > label {
  background-color: white;
  color: black;
}
.bg-none {
  /* border:1px solid black */
}
.border {
  border: 1px solid black;
}
.bg-white {
  background-color: white !important;
  color: black;
  border: 1px solid black !important;
}
.bgwhite {
  background-color: white !important;
  color: black;
}

.bold {
  font-weight: bold;
}
.dark .border {
  border-color: black !important;
}
.text-dark {
  color: black !important;
}
</style>
